"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".folded:not(.unfolded)   [_nghost-%COMP%] > .group-title[_ngcontent-%COMP%]{align-items:center}.folded:not(.unfolded)   [_nghost-%COMP%] > .group-title[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{opacity:0;transition:opacity .2s ease}.folded:not(.unfolded)   [_nghost-%COMP%] > .group-title[_ngcontent-%COMP%]:before{content:\"\";display:block;position:absolute;min-width:1.6rem;border-top:2px solid;opacity:.2}"];
exports.styles = styles;
