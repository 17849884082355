
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IConfiguration } from './config';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IConfiguration = {
    production: isEqual('false', 'true'),
    hmr: isEqual('false', 'true'),
    services: {
        data: {
            endpoint: 'wss://data.buymyplace.com.au/v1/graphql',
        },
        googleMaps: {
            key: 'AIzaSyC4WdUw_24mHBgG_jrPefVKKHLXFCzh6SA'
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: 'pbeELIVuXk7aoLyYmkFSqa94ihV1jxST',
            },
        },
        sentry: {
            sentryDsnVendor: 'https://c11b9d36e4ae476ca337005b814b4752@o402650.ingest.sentry.io/5569314',
        },
    },
    application: {
        branch: 'bmp-production',
        buildDate: 'Wed, 06 Nov 2024 10:47:12 GMT',
        buildNumber: '20241106_104710',
        version: '0.0.10',
        gitHead: '1b76a05'
    }
};
